import React from 'react'
import {
  ContentSlide,
  ContentPromotionBar,
  WrapperPromotionBar,
  LinkPromotionBar,
  ButtonPromotionBar,
} from './PromotionBar.style'
import { IPlacement, isLXTeaser } from '@typesApp/cmsPlacement/Placement'
import { ILXTeaser, ITeaserCallToAction } from '@typesApp/cmsPlacement/LXTeaser'
import { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useStoreIdentity } from '../../../../foundation/hooks/useStoreIdentity'
import { toInteger } from 'lodash-es'
import { useExternalsActions } from '@hooks/useExternalsAction'
import { getCtaStyle } from '@components/Cms/CmsComponents-CSS/CmsCta'
import { convertToPascalSnakeCase } from '@utils/common'
import { CmsCountdown } from '@components/Cms/CmsComponents-CSS/CmsCountdown'
import countdownStyles from './styles/Countdown.module.scss'
import clsx from 'clsx'
import styles from './styles/index.module.scss'

export interface IProps {
  data: IPlacement
}

//Remvoves any html tags
const normalizedTeaserText = teaserText => teaserText?.replace(/<.*?>/gi, '') || ''

const PromotionBar: React.FC<IProps> = ({ data }) => {
  const teaserItems = data?.items.filter(isLXTeaser) || []
  const promoBarName = data?.name ?? ''

  /*
 The reason for all this calculation is that we dont want the banner to be changing height for every
  SwiperSlide, making the page jumpy
 */
  const getMobileMinHeight = (teaserItems): number => {
    const minHeightPerLine = toInteger(16)
    let hasCta = false
    let maxCharCount = 0
    const defaultCharCountWithCTA = 20
    const defaultCharCountWithoutCTA = 40

    teaserItems.forEach(item => {
      if (!!item.teaserLXCallToActionSettings.length && !hasCta) {
        hasCta = true
      }

      maxCharCount = Math.max(maxCharCount, normalizedTeaserText(item.teaserText2).length)
    })

    //For cases without a CTA, the trigger to extend the height is bigger
    const defaultCharCount = hasCta ? defaultCharCountWithCTA : defaultCharCountWithoutCTA

    if (maxCharCount <= defaultCharCount) {
      return defaultCharCount
    }
    //Add  16px (htmlfontsize) per extra line (around 20 chars on the smallest screen)
    return defaultCharCount + Math.ceil((maxCharCount / defaultCharCount) * minHeightPerLine)
  }

  return (
    <WrapperPromotionBar
      mobileMinHeight={getMobileMinHeight(teaserItems)}
      data-cm-metadata={`[{"_":"properties.placement-${promoBarName}"},{"placementRequest":[{"isInLayout":true,"hasItems":${true},"placementName":"${promoBarName}"}]}]`}
    >
      <ContentPromotionBar>
        <Swiper
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          direction={'vertical'}
          loop={teaserItems?.length >= 2}
          slidesPerView={1}
          modules={[Autoplay]}
          allowTouchMove={false}
        >
          {teaserItems.map(item => (
            <SwiperSlide key={item.id}>
              <TextLinkPromoBar item={item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </ContentPromotionBar>
    </WrapperPromotionBar>
  )
}

const CallToAction: React.FC<{
  actionSettings: ITeaserCallToAction
}> = props => {
  const { actionSettings } = props
  const { basePath } = useStoreIdentity()
  const callToActionText = actionSettings?.callToActionText || ''
  const style = actionSettings?.style || ''
  const actions = useExternalsActions()

  if (actionSettings.target?.type === 'Action') {
    const action = actions[actionSettings.target.idAction]

    const { color } = getCtaStyle(style)
    return (
      <ButtonPromotionBar
        data-element-id={'0PromotionBar_Text0_CTA'}
        data-description={convertToPascalSnakeCase(callToActionText)}
        variant={color}
        onClick={action}
      >
        {callToActionText}
      </ButtonPromotionBar>
    )
  }

  const formattedUrl = actionSettings?.target?.formattedUrl || ''
  const targetUrl = actionSettings?.target?.['url'] || ''
  const link = formattedUrl ? `${basePath}${formattedUrl ?? ''}` : targetUrl
  return (
    <LinkPromotionBar
      data-element-id={'0PromotionBar_Text0_LINK'}
      data-description={convertToPascalSnakeCase(callToActionText)}
      href={link}
      external={!formattedUrl}
    >
      {callToActionText}
    </LinkPromotionBar>
  )
}

const TextLinkPromoBar: React.FC<{ item: ILXTeaser }> = ({ item }) => {
  const teaserLXCallToActionSettings: ITeaserCallToAction = item.teaserLXCallToActionSettings[0]
  let text = item.teaserText2
  if (text.includes('<a') && text.includes('data-href')) {
    // Add href attribute to an anchor tag (if text has an anchor tag) and use the value from data-href attribute from CMS
    // Note: I didn't remove the data-href attribute because it might be used for other purposes
    text = text.replace(/<a([^>]+)data-href=['"]([^'"]+)['"]([^>]*)>/g, '<a$1data-href="$2"$3 href="$2">')
  }

  return (
    <SwiperSlide>
      <ContentSlide>
        <CmsCountdown styleOverride={countdownStyles} teaser={item} />
        <div
          className={clsx(styles['text-promotion-bar'])}
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
        {teaserLXCallToActionSettings && <CallToAction actionSettings={teaserLXCallToActionSettings} />}
      </ContentSlide>
    </SwiperSlide>
  )
}

export default PromotionBar
